@import "../../../style/variables.less";

.store-master-form-wrapper {
  display: flex;
  justify-content: space-between;
  font-family: Noto Sans CJK JP;
}

.section-collapsed {
  margin-left: 50px;
  .store-master-form-container {
    width: calc(100vw - 462px) !important;
    padding-right: 8px;

    @media screen and (max-width: 800px) {
      width: calc(100% - 108px) !important;
    }
  }
}

.form-container {
  font-family: Noto Sans CJK JP;
  .store-master-form-container {
    padding-left: 40px;
    padding-right: 25px;
    padding-top: 50px;
    padding-bottom: 65px;
    .store-master-form-heading {
      font-size: 24px;
      font-weight: bold;
      margin-left: 11.5px;
      line-height: 24px;
    }

    .store-master-card {
      padding: 32px;
      border-radius: 5px;
      background: #fff;
      border: 1px solid @error-color;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

      &-1 {
        // height: 445px;
        height: auto;
      }

      &-2 {
        // height: 345px;
        height: auto;
      }

      &-3 {
        // height: 999px;
        height: auto;
      }

      &-marginTop {
        margin-top: 32px;
      }

      &__heading {
        h2 {
          border-bottom: 1px solid @error-color;
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 16px;
          color: @primary-color;
        }
      }

      &__details {
        font-size: 14px;

        &-marginTop {
          margin-top: 32px;

          &::before,
          &::after {
            content: "";
            display: table;
          }
        }

        .store-master-form {
          &__customLabel {
            display: flex;
            align-items: center;

            &-required {
              width: 32px;
              height: 17px;
              background-color: @success-color;
              border-radius: 2px;
              color: white;
              font-size: 10px;
              font-style: normal;
              font-weight: bold;
              padding: 0px 7px 2px 5px;
              margin-right: 10px;
              line-height: 15px;
            }

            &-text {
              display: inline-block;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              padding-top: 5px;
              color: #111111;
            }
          }
          &__inputField {
            width: 212px;
            height: 40px;
            margin-top: 8px;

            &-flex {
              display: flex;
              align-items: center;
              width: 100%;
            }

            & > span {
              font-size: 16px;
              margin: 0 8px 0 8px;
              color: @link-color;
              display: inline-block;
            }
          }

          &__numberField {
            p {
              margin: 0;
            }
          }

          &__customInputField {
            height: 40px;
            &-w1 {
              width: 212px;

              &:not(:last-child) {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  #list-items-background-color .ant-list-item {
    &:nth-child(odd) {
      background-color: #fafafa;
    }
    //padding: 25px;
  }

  .u-d-flex {
    display: flex;
  }

  .u-ai-c {
    align-items: center;
  }

  .u-jc-sb {
    justify-content: space-between;
  }

  .prevent-margin-collapse::before,
  .prevent-margin-collapse::after {
    content: " ";
    display: table;
  }

  .u-marginTop-5 {
    margin-top: 5px;
  }

  .infinite-scroll-container {
    height: 195px;
    overflow: auto;
    border-bottom: 1px solid @error-color;
    border-top: 1px solid @error-color;
    //border-radius: 3px;
  }

  .u-d-inline-block {
    display: inline-block;
  }

  .db {
    &-1 {
      background-color: #b1a9a9;
    }
    &-2 {
      background-color: orangered;
    }
    &-3 {
      background-color: #920e2f;
    }
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.elipsis-p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: @success-color;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: @success-color;
}
.ant-radio-group-solid .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #fff;
}

.ant-input:focus {
  background: #eeeff5;
  border: 1px solid @primary-color;
  box-sizing: border-box;
  border-radius: 2px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #eeeff5;
  border: 1px solid @primary-color;
  box-sizing: border-box;
  border-radius: 2px;
}
.number-pad-dropdown {
  .ant-dropdown {
    top: 40.09px !important;
  }
}

.phone-number-pad-dropdown {
  .ant-dropdown {
    top: -2px !important;
    left: 222px !important;
  }
}

.phone-number-input {
  width: 100% !important;

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.red-border {
  border-color: #eb516d;
}

.number-of-people-input {
  height: 40px;
  line-height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 5px 11px;
  background: #fafafa;
}

.number-of-people-input::-webkit-outer-spin-button,
.number-of-people-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.business-hour-add-btn {
  height: 40px;
  border-radius: 4px;
  width: 45px;
  padding-top: 10px;
  background: #121958;
}

.business-hour-delete-btn {
  height: 40px;
  border-radius: 4px;
  width: 45px;
  padding-top: 10px;
  background: #eb516d;
}

.business-hour-delete-btn:hover,
.business-hour-delete-btn:focus,
.business-hour-delete-btn:active {
  background: #eb516d;
}

.business-hour-add-btn:hover,
.business-hour-add-btn:focus,
.business-hour-add-btn:active {
  background: #121958;
}

.span-padding-left-right {
  padding: 0px 13.5px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  z-index: 0 !important;
}

.ant-input-wrapper{
  input{
      height: 40px;
      color: @link-color !important;
  }
}

.flex-center {
  display: flex;
  justify-items: center;
}

.gray-border {
  padding: 5px;
  border: 1px solid #d8d8d8;
}

.dianping-business-hour {
  background: #e7ecf6;
  padding: 3px 0 3px 14px;
  border-radius: 99px;
  margin: 3px;
}

.clickable-icon {
  padding: 5px 10px;
}

.clickable-icon:hover {
  cursor: pointer;
}

@primary-color: #121958;